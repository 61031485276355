<template>
  <tr>
    <td :style="indMarca < marcasModeloContrateCar.length - 1 ? 'border: 0px solid black;' : ''"></td>
    <td
      :style="indMarca < marcasModeloContrateCar.length - 1 ? 'border: 0px solid black;' : ''"
      colspan="2"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          md="1"
        >
          <v-checkbox
            v-model="model.check"
            hide-details
            class="my-0"
            :disabled="$store.state.app.onlyShow"
            @change="changeCheck()"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="11"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="12"
            >
              <span
                v-for="(car, indCar) in marca.cars_id"
                :key="indCar"
              >
                {{ car.name }}
                <br />
              </span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </td>
    <td :style="indMarca < marcasModeloContrateCar.length - 1 ? 'border: 0px solid black;' : ''">
      <fragment v-if="model.check">
        <fragment
          v-for="(tarifaCombustible, indTarifaCombustible) in suplementoCar.tarifas_combustible"
          :key="indTarifaCombustible"
        >
          <fragment v-if="tarifaCombustible.marca_id === marca.marca_id">
            <v-text-field
              v-model="tarifaCombustible.price"
              :label="$t('lbl.price')"
              outlined
              dense
              hide-details
              prefix="$"
              type="number"
              width="auto"
              :disabled="$store.state.app.onlyShow"
            ></v-text-field>
          </fragment>
        </fragment>
      </fragment>
    </td>
    <td :style="indMarca < marcasModeloContrateCar.length - 1 ? 'border: 0px solid black;' : ''"></td>
    <td :style="indMarca < marcasModeloContrateCar.length - 1 ? 'border: 0px solid black;' : ''"></td>
    <td :style="indMarca < marcasModeloContrateCar.length - 1 ? 'border: 0px solid black;' : ''"></td>
  </tr>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiMenuDown, mdiMenuRight } from '@mdi/js'

export default {
  props: {
    indMarca: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop
    marca: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    suplementoCar: {
      type: Object,
    },
    indSuplementoCar: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      model: {
        check: false,
      },
      showRow: false,
      indexRow: -1,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      suplementosContrateCar: state => state.app.suplementosContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
    }),
  },

  mounted() {
    this.suplementosContrateCar[this.indSuplementoCar].tarifas_combustible.forEach(element => {
      if (element.marca_id === this.marca.marca_id) {
        this.model.check = true
      }
    })
  },
  methods: {
    ...mapMutations(['addTarifaCombustibleSuplementoContrateCar', 'deleteTarifaCombustibleSuplementoContrateCar']),
    changeCheck() {
      if (this.model.check) {
        this.addTarifaCombustibleSuplementoContrateCar({
          indSuplementoCar: this.indSuplementoCar,
          category_id: null,
          marca_id: this.marca.marca_id,
          price: 0,
        })
      } else {
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < this.suplementosContrateCar[this.indSuplementoCar].tarifas_combustible.length;
          // eslint-disable-next-line no-plusplus
          index++
        ) {
          const element = this.suplementosContrateCar[this.indSuplementoCar].tarifas_combustible[index]

          if (element.marca_id === this.marca.marca_id) {
            this.deleteTarifaCombustibleSuplementoContrateCar({ pos: index, indSuplementoCar: this.indSuplementoCar })
            break
          }
        }
      }
    },
  },
}
</script>
